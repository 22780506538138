<script setup lang="ts">
import { StarsProps } from "./Stars.props"

const props = withDefaults(defineProps<StarsProps>(), {
  size: 4,
  gap: 1,
  isClickable: false
})

// min 0, max 5
const countStars = computed(() =>
  props.stars
    ? Math.round(props.stars >= 5 ? 5 : props.stars <= 0 ? 0 : props.stars)
    : 0
)

const starOnHover = ref(0)
const selectedStar = ref(0)

const stars = (quantity: number) => (starOnHover.value = quantity)

const starQuantity = computed(() =>
  selectedStar.value > 0 ? selectedStar.value : starOnHover.value
)

const emit = defineEmits<{ (e: "onReviewSaved", review: number): void }>()

const handleReviewRegistration = (review: number) => {
  selectedStar.value = review
  emit("onReviewSaved", review)
}
</script>

<template>
  <div v-if="isClickable" class="flex cursor-pointer" :class="`gap-${gap}`">
    <UtilsIcon
      name="StarFilled.svg"
      color="yellow-main"
      v-for="x in starQuantity"
      @click="handleReviewRegistration(x)"
      @mouseleave="starOnHover = 0"
      @mouseover="stars(x)"
      :class="`h-${size} w-${size}`"
      :preload="preloadIcons ? true : undefined"
      :fetch-priority="preloadIcons ? 'high' : undefined"
    />

    <UtilsIcon
      name="Star.svg"
      color="yellow-main"
      v-for="x in 5 - starQuantity"
      @mouseover="stars(x)"
      @click="handleReviewRegistration(x + selectedStar)"
      :class="`h-${size} w-${size}`"
      :preload="preloadIcons ? true : undefined"
      :fetch-priority="preloadIcons ? 'high' : undefined"
    />
  </div>

  <div
    v-else-if="countStars >= 0 || countStars <= 5"
    class="flex"
    :class="`gap-${gap}`"
  >
    <UtilsIcon
      name="StarFilled.svg"
      color="yellow-main"
      v-for="i in countStars"
      :class="`h-${size} w-${size}`"
      :preload="preloadIcons ? true : undefined"
      :fetch-priority="preloadIcons ? 'high' : undefined"
    />
    <UtilsIcon
      name="Star.svg"
      color="yellow-main"
      v-for="i in 5 - countStars"
      :class="`h-${size} w-${size}`"
      :preload="preloadIcons ? true : undefined"
      :fetch-priority="preloadIcons ? 'high' : undefined"
    />
  </div>
</template>
